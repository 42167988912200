var Progress;

// Чтобы на ломать серверный рендеринг при импорте компонента в реакт.
var progressBarWrap = typeof document !== 'undefined' ? document.getElementById("js-navPrBar") : null;
if (progressBarWrap) {
	var defaultConfig = {text : "Сервис временно недоступен" , showResend : false}
	var errorMsg = progressBarWrap.getElementsByClassName("navErrorMsg")[0];
    var errorMsgTxt = errorMsg.getElementsByClassName("navErrorMsgTxt")[0];
    var resendLnk = errorMsg.getElementsByClassName("resendNavReq")[0];
    var closeBtn = progressBarWrap.getElementsByClassName("closeNavPr")[0];
    
    var errorsConfig = {} 
    if (errorMsg.hasAttribute("data-errors")){
    	errorsConfig = JSON.parse(errorMsg.getAttribute("data-errors"));	
    } 
    
    /**
     * Функция закрытия сообщения об отсутствии интернета.
     */
    closeBtn.addEventListener("click", function() {
        cancel(true);
    });

    /**
     * Listener для кнопки повторного ajax запроса в случае, если пропал интернет.
     * Функция Progress.resend передается из GWT.
     * @see one.app.community.dk.gwt.hook.client.hooks.navigation.progress.NavigationProgressUtil#setResendRequestListener(OdklRequestBuilder builder)
     */
    resendLnk.addEventListener("click", function() {
        if (Progress.resend) {
            Progress.resend();
        }
    });
}
var slowInternetTimeout;
var darkTimeout;

/**
 * Таймаут для начала показа прогресса после начала запроса
 */
var startTimeout;

/**
 * Таймаут для того, чтобы дать прогрессу дойти до конца перед закрытием.
 */
var finishTimeout;

function toggleProgressBar(show) {
    progressBarWrap.classList.toggle("__collapse", !show);
    if (!show) {
        darkTimeout = setTimeout(function () {
            progressBarWrap.classList.remove("__notice");
            Progress.resend = null;
        }, 200);
    }
}

/**
 * Расширяет прогресс бар для вывода в нём сообщения.
 */
function expandProgressBar() {
    progressBarWrap.classList.remove("__slim");
    progressBarWrap.classList.add("__expand");
}

function slimDownProgressBar() {
    progressBarWrap.classList.add("__slim");
    progressBarWrap.classList.remove("__expand");
}

function toggleSlowInternetMessage(show) {
    slowInternetMsg.classList.toggle("invisible", !show);
}
   
function getErrorConfig(key){
	var text = errorsConfig[key];
	if (text === undefined){
		return defaultConfig;
	}else{
		return text;
	}
}

function showErrorMessage(key) {
	
	expandProgressBar();
	var conf = getErrorConfig(key);
	errorMsgTxt.textContent = conf.text;
	toggleResend(conf.showResend);
    toggleMsg(true);
    
}

function toggleResend(show) {
	resendLnk.classList.toggle("invisible", !show);
    
}

function toggleMsg(show) {
	closeBtn.classList.toggle("invisible", !show);
    errorMsg.classList.toggle("invisible", !show);
    progressBarWrap.classList.toggle("__notice", show);
}

function showNoInternetMessage() {
    showErrorMessage("internet.no");
}

function showSlowInternetMessage() {
    showErrorMessage("internet.slow");
}

function showServiceUnavailableMessage() {
 	   showErrorMessage("service-unavailable");
}

/**
 * Прячет любое сообщение в прогресс баре и сужает его.
 */
function hideMessage() {
    slimDownProgressBar();
    toggleMsg(false);
    toggleResend(false);
	errorMsgTxt.textContent = "";
}

   
/**
 * Сбрасываем загрузку прогресс бара.
 * @param {boolean} hide если true, тогда прогресс бар прячется полностью
 */
function cancel(hide) {
    clearTimeout(startTimeout);
    clearTimeout(finishTimeout);
    clearTimeout(darkTimeout);
    clearTimeout(slowInternetTimeout);
    hideMessage();
    progressBarWrap.classList.add("__no-anim");
    if (hide) {
        toggleProgressBar(false);
    }

    progressBarWrap.classList.remove("__milestone");
    progressBarWrap.classList.remove("__complete");
}

function isProgressVisible() {
    return !progressBarWrap.classList.contains("__collapse");
}

function startSlowInternetTimer(timeout) {
    slowInternetTimeout = setTimeout(function() {
        clearTimeout(slowInternetTimeout);
        showSlowInternetMessage();
    }, timeout);
}

function showProgress() {
    progressBarWrap.classList.add("__milestone");
}

function startProgress(showProgressTimeout, slowInternetTimeout) {
    if (!isProgressVisible()) {
        startTimeout = setTimeout(function() {
            toggleProgressBar(true);
            showProgress();
        }, showProgressTimeout);
    } else {
        showProgress();
    }
    startSlowInternetTimer(slowInternetTimeout);
}

Progress = {
    /**
     * Поля устанавливаются через GWT
     * @see one.app.community.dk.gwt.hook.client.hooks.navigation.progress.NavigationProgressUtil#setResendRequestListener(OdklRequestBuilder builder)
     */
    resend: null,
    /**
     * @see one.app.community.dk.gwt.hook.client.hooks.navigation.progress.NavigationProgressUtil.setStartTimeout
     */
    startTimeout: null,
    /**
     * @see one.app.community.dk.gwt.hook.client.hooks.navigation.progress.NavigationProgressUtil.setSlowInternetTimeout
     */
    slowTimeout: null,

    showProgress: function(startTimeout) {
        if (!progressBarWrap) {
            return;
        }

        cancel(false);
        progressBarWrap.offsetHeight; // Борьба с оптимизацией хрома на пересчет свойств с transition
        progressBarWrap.classList.remove("__no-anim");
        startProgress(startTimeout || this.startTimeout, this.slowTimeout);
    },

    finishProgress: function(timeout) {
        if (!progressBarWrap) {
            return;
        }
        clearTimeout(startTimeout);
        clearTimeout(slowInternetTimeout);
        progressBarWrap.classList.add("__complete");
        progressBarWrap.classList.remove("__milestone");
        finishTimeout = setTimeout(function() {
            clearTimeout(finishTimeout);
            hideMessage();
            toggleProgressBar(false);
        }, timeout);
    },

    showNoInternetError: function() {
        if (!progressBarWrap) {
            return;
        }
        cancel(false);
        toggleProgressBar(true);
        showNoInternetMessage();
    },
    
    showServiceUnavailable: function() {
        if (!progressBarWrap) {
            return;
        }
        cancel(false);
        toggleProgressBar(true);
        showServiceUnavailableMessage();
    }
    
};

export default Progress;
